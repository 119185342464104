@font-face {
  font-family: 'product-sans';
  src: url(./assets/fonts/Product\ Sans\ Regular.ttf);
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, Product Sans "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  /* font-family: -apple-system, 'product-sans', "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue" !important; */
  font-family: 'product-sans' !important;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eeeeee;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body::-webkit-scrollbar,
.ant-modal-wrap::-webkit-scrollbar {
  width: 0.5em;

  @media (max-width: 767px) {
    width: 0 !important;
    display: none !important;
  }
}

body::-webkit-scrollbar-track,
.ant-modal-wrap::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);

  @media (max-width: 767px) {
    box-shadow: none;
  }
}

body::-webkit-scrollbar-thumb,
.ant-modal-wrap::-webkit-scrollbar-thumb {
  background-color: rgb(97, 96, 96);
  outline: 1px solid rgb(112, 128, 144);
  border-radius: 5px;

  @media (max-width: 767px) {
    display: none;
  }
}

.ant-modal-wrap::-webkit-scrollbar-thumb {
  display: none;
}