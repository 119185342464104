.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.content-wrap {
  position: relative;
  transition: ease-in-out 0.5s;
  float: left;
  width: 100%;
}

.openSider .content-wrap {
  padding: 0px;
}
.msgOpenSider .content-wrap {
  padding: 0px;
}
.msgOpenSider .msg-left-panel {
  transform: translateY(130%);
  box-shadow: none;
}

@media (max-width: 991.98px) {
  .content-wrap {
    padding: 0;
  }

  .openSider .back-drop {
    display: none;
  }
  .msgOpenSider .msg-back-drop {
    display: none;
  }
  .msgOpenSider .msg-left-panel {
    /* left: 0; */
    box-shadow: none;
  }


  .msg-back-drop {
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(10px);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 11;
    min-height: 100vh;
  }

  .back-drop {
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 10;
    min-height: 100vh;
  }
}

.ant-picker-panel-container {
  background: #ffffff !important;
}

.apexcharts-legend.apx-legend-position-bottom.apexcharts-align-center,
.apexcharts-legend.apx-legend-position-top.apexcharts-align-center {
  position: relative !important;
}

@media (max-width: 768px) {

  .apexcharts-legend.apx-legend-position-bottom.apexcharts-align-center,
  .apexcharts-legend.apx-legend-position-top.apexcharts-align-center {
    position: absolute !important;
  }
}

.openSider .sticky-header {
  width: 100% !important;
}

.openSider .content-wrap {
  padding: 0px;
}
.msgOpenSider .content-wrap {
  padding: 0px;
}

.openSider .left-panel {
  left: -280px;
  box-shadow: none;
}

@media (max-width: 991.98px) {
  .content-wrap {
    padding: 0;
  }

  .openSider .back-drop {
    display: none;
  }

    .back-drop {
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 10;
    min-height: 100vh;
  }
}

@media (min-width: 991.98px) {
  .openSider .left-panel {
    left: 0;
    box-shadow: none;
  }
}

@media (max-width: 425.98px) {
  .ant-modal-body {
    padding: 15px;
  }
}

.modal_close_icon {
  font-size: 50px;
  line-height: 34px;
  color: #2B353F;
}

.modal_wrapper .ant-modal-content {
  border-radius: 5px;
  border: 1px solid #eeeeee;
  background: #ffffff;
}

.modal_wrapper .ant-checkbox-inner {
  border-radius: 5px;
}

.modal_wrapper .ant-modal-close {
  top: 14px;
  right: 14px;
}

.modal_wrapper .ant-modal-body {
  padding: 30px 30px 10px 30px !important;

}

@media (max-width: 575.98px) {
  .modal_wrapper .ant-modal-body {
    padding: 25px 30px !important;
  }
}

@media (max-width: 400px) {
  .modal_wrapper .ant-modal-body {
    padding: 15px 25px !important;
  }
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: #ffffff;
}

.ant-table {
  border-radius: 5px !important;
  /* border: 1px solid #aaaaaa; */
}

.ant-menu-vertical {
  overflow: hidden !important;
}

.ant-menu-horizontal>.ant-menu-submenu::after {
  border-bottom: 2px solid transparent !important;
}

.ant-menu-horizontal {
  border-bottom: none !important;
}

.add__org {
  background: #FFEADE !important;
}

.add__org a {
  color: #F67C36 !important;
}

.ant-menu-vertical .ant-menu-item {
  margin: 0 !important;
  height: 45px !important;
}

.apexcharts-toolbar {
  z-index: 9 !important;
}

.ant-dropdown-placement-bottom>.ant-dropdown-arrow {
  top: 0;
}

.ant-dropdown-show-arrow.ant-dropdown-placement-bottom {
  padding-top: 6px;
}

/* ::-webkit-scrollbar,
.ant-modal-wrap::-webkit-scrollbar {
  width: 0;

  @media (max-width: 767px) {
    width: 0 !important;
    display: none !important;
  }
} */

.ant-menu-submenu-popup {
  margin-top: -25px;

}

@media (max-width: 1550px) {
  .modal_wrapper .ant-modal-body {
    padding: 35px 35px 35px 35px !important;

  }

  .ant-menu-vertical .ant-menu-item {
    margin: 0 !important;
    height: 36px !important;
  }

  .ant-picker-dropdown-placement-bottomLeft .ant-picker-range-arrow {
    display: none;
  }

}

@media (max-width: 600px) {
  .ant-picker-panel-container .ant-picker-panels {
    flex-direction: column;
  }
}

.ant-menu-submenu-popup>.ant-menu {
  margin-top: -5px !important;
  border-radius: 0 0 5px 5px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {

  opacity: 0;

}

.ant-pagination-item {
  font-family: 'product-sans' !important;
}

.ant-menu-vertical.ant-menu-sub>.ant-menu-item {
  display: flex;
}

.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background: #fff;
}

@media (max-width: 425.98px) {
  .modal_wrapper .ant-modal-body {
    padding: 15px 15px 0px 15px !important;
  }

  .modal_wrapper .ant-modal-close {
    top: 0;
    right: 0;
  }
}