.header_tree_select_dropdown {
  max-height: 682px;
  overflow: auto;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  border: 1px solid #e5e5e5;
  /* width: 100% !important;
  max-width: 380px;
  min-width: 0 !important; */
  /* top: 65px !important; */
  /* position: fixed; */
}

.header_tree_select_dropdown .anticon svg {
  display: none;
}

.header_popover_wrap {
  width: 606px;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.06);
  top: 55px !important;
  position: fixed;
}

.header_popover_wrap .ant-popover-inner {
  border-radius: 5px;
  border: 1px solid #e5e5e5;
}

.header_popover_wrap .ant-popover-arrow {
  display: none;
}

.header_popover_wrap .ant-popover-title {
  border-bottom: none;
  padding: 27px 16px 32px;
}

.header_popover_wrap .ant-popover-title span::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #232333;
  border-radius: 5px;
  height: 1px;
  width: 100%;
}

.header_popover_content {
  padding: 0;
}

.header_dropdown {
  position: fixed;
}

@media (max-width: 991.98px) {

  .header_popover_wrap {
    width: 100%;
  }
}

@media (max-width: 575.98px) {
  .header_popover_wrap .ant-popover-title span {
    font-size: 20px;
  }
}